// import React, { Component } from 'react';
import React, {useState} from "react";
//import { useNavigate } from "react-router-dom";
import axios from "axios"


// importing react-bootstrap components
//import { Container, Table } from 'react-bootstrap';

// Go to '/src/shared/YourInformation.ts' file to edit information 
//import { basicinfo } from '../../shared/YourInformation';

 interface Contact {
    name: string,
    email: string,
    message: string
}
//export default class ContactPage extends Component {
export const ContactPage: React.FC = () => {
    const initContact = {name: '', email: '', message: ''};
    const [contact, setContact] = useState<Contact>(initContact);
    const [errorMsg, setErrorMsg] = useState<string>('');


    const onChangeFields = (e: React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name;
        let value = e.target.value;
        setContact({...contact, [name]: value});
    }

    const clickLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const _URL = "https://api.vantypham.com/mail"; //AWS API Gateway
        const res = await axios.post(_URL, contact);
        if (res.status === 200) {
            //call login service
            //navigate("/products"); //do not need to navigate
            alert('Thank you! An message has been sent to https://api.vantypham.com/mail Rest Endpoint.');
        } else {
            setErrorMsg(" failed!!!");
        }
        
    }
    
    // public render() {
        return (<>
                
                    <h1 id="main-banner-h1"> Contact me</h1>
                    <form onSubmit={clickLogin}>
                    <div className="profile-info container2">
                        <label>Your Name</label>
                        <input type="text" id="name" name="name" value={contact.name} onChange={onChangeFields} required placeholder="Your name.." />
                        <label>Your Email ID (an email will be sent to your mail box after you submitted)</label>
                        <input type="text" id="email" name="email" value={contact.email} onChange={onChangeFields} required placeholder="Your email.." />
                        <label>Subject</label>
                        <textarea id="subject" name="message"  placeholder="Write something.."></textarea>
                        <input type="submit" value="Submit"></input>
                    </div>
                </form>
                </>
            )
}
