import React, { Component } from 'react';

// importing react-bootstrap components
import { Container, Table } from 'react-bootstrap';

// Go to '/src/shared/YourInformation.ts' file to edit information 
//import { hobbiesinfoList } from '../../shared/YourInformation';
// BLOG MENU
export default class HobbiesPage extends Component {

    public render() {
        return (<>
                <Container>
                    <h1 id="main-banner-h1">Understanding OAuth 2.0 and OpenID Connect: A Comprehensive Guide with Java Demo</h1>
                    
                    <p>In today's interconnected digital world, managing authentication and authorization efficiently is crucial. OAuth 2.0 and OpenID Connect (OIDC) are two widely used protocols that provide secure and reliable mechanisms for user authentication and authorization. This blog will explain what OAuth 2.0 and OIDC are, how they work, when to use them, and provide a step-by-step guide to implement OIDC in a Java application.</p>
                    <h3>What is OAuth 2.0?</h3>
                    <p>OAuth 2.0 is an open authorization framework that allows applications to obtain limited access to user accounts on an HTTP service. It works by delegating user authentication to the service that hosts the user account, and authorizing third-party applications to access the user's account.</p>
                    <h4><i>Key Concepts of OAuth 2.0</i></h4>
                    <ol>
                        <li>Resource Owner: The user who authorizes an application to access their account.</li>
                        <li>Client: The application requesting access to the user's account.</li>
                        <li>Authorization Server: The server that authenticates the user and issues access tokens to the client.</li>
                        <li>Resource Server: The server hosting the protected resources, capable of accepting and responding to protected resource requests using access tokens.</li>
                    </ol>
                    <h4><i>OAuth 2.0 Grant Types</i></h4>
                    <ol>
                        <li>Authorization Code: Used in server-side applications.</li>
                        <li>Implicit: Used in client-side applications.</li>
                        <li>Resource Owner Password Credentials: Used when the user trusts the client with their credentials.</li>
                        <li>Client Credentials: Used for machine-to-machine authentication.</li>
                    </ol>

                    <h3>What is OpenID Connect?</h3>
                    <p>OpenID Connect (OIDC) is an identity layer built on top of OAuth 2.0. It enables clients to verify the identity of the end-user based on the authentication performed by an authorization server and to obtain basic profile information about the end-user.</p>
                    <h4><i>Key Concepts of OIDC</i></h4>
                    <ol>
                        <li>ID Token: A JSON Web Token (JWT) that contains user profile information</li>
                        <li>UserInfo Endpoint: An endpoint that returns user profile information.</li>
                    </ol>
                    <h4><i>When to Use OAuth 2.0 and OpenID Connect</i></h4>
                    <ul>
                        <li>OAuth 2.0 is ideal for authorizing third-party applications to access user data without exposing user credentials.</li>
                        <li>OpenID Connect is perfect for applications that need to authenticate users and obtain user profile information.</li>
                    </ul>
                    <h4><i>Use Cases:</i></h4>
                    <ul>
                        <li>Single Sign-On (SSO): Allowing users to log in once and gain access to multiple applications</li>
                        <li>API Authorization: Granting applications access to user data via APIs.</li>
                        <li>Mobile and Web Applications: Securely managing user authentication and authorization in mobile and web apps.</li>
                    </ul>

                    <h3>Implementing OpenID Connect in Java</h3>
                    <p>Let's walk through a simple example of how to implement OpenID Connect in a Java application using the Spring Security framework</p>
                    <h4><i>Flow</i></h4>
                    <img id="architecture-photo-blog" src="./assets/Oauth2-flow1-ClientAppwebserver.png" alt="Flow" />
                    <h4><i>Source Code</i></h4>
                    
                    <a href='https://github.com/vantypham/spring-openid-with-autho-server-resource-server-and-client-app' target="_blank">
                    https://github.com/vantypham/spring-openid-with-autho-server-resource-server-and-client-app
                                                </a>
                    
                    
                    <h3>Conclusion</h3>
                    <p>OAuth 2.0 and OpenID Connect are powerful protocols for managing authentication and authorization in modern applications. OAuth 2.0 focuses on authorization, while OIDC adds an identity layer to OAuth 2.0 for user authentication. By understanding and implementing these protocols, you can enhance the security and user experience of your applications. Happy coding!</p>




                </Container>
            </>
        )
    }
}
 