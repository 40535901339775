import React, { Component } from 'react';

// importing react-bootstrap components
import { Container } from 'react-bootstrap';

// Go to '/src/shared/YourInformation.ts' file to edit information 
import { experienceinfoList } from '../../shared/YourInformation';


export default class ExperiencePage extends Component {

    public render() {
        return (<>
                <Container>
                    <h1 id="main-banner-h1">Experience</h1>
                    <div className="info">
                        <div>
                            <h3>Professional Development</h3>
                            <p><b>e-Commerce Project (01/2022-08/2023)</b></p>
                            <ul>
                                <li>Built a Web Store application to allow customers to buy products and pay by credit card using Angular 16 component-based and reactive web with Observable, NgRx, TyeScript, Karma & Jasmine test, Node JS, deployed to AWS S3, API gateway, Lambda, and DynamoDB using AWS CodeBuild, CodeDeploy</li>
                                <li>Strong knowledge and skills of design API first, build, test, deploy, govern APIs using Mule Anypoint Platform and
                                Mulesoft Anypoint Studio, DataWeave, and MUnit.</li>
                                
                            </ul>
                        </div>
                        <div>
                            <h3>Technical Team Lead </h3>
                            <p><b>Financial Project (01/2022-08/2023)</b></p>
                            <ul>
                                <li>Developed type-safe applications using Angular 8, TypeScript, JSON, Angular Material to enhancing code
reliability and maintainability for the Partner Central and Developer Portal websites. Optimizing the user
experience through efficient state management with NgRx, reactive programming with Observable</li>
                                <li>Built and managed API Gateway (Kong), handling the control plane of configuration, routing to upstream API
services, installed customized plugins to manage authentication, authorization (Key Auth, OAuth2, OIDC), rate
limit, logging, and security using Kong Admin API, Kong Manager, Kong Plugins ensuring security standards were
met, and allowing for external apps and mobile apps to connect.</li>
                                <li>Developed robust API Services that handled thousands of concurrence requests per second by using Java,
REST, Spring Boot, and Microservices, facilitating modular, easy-to-maintain, and scalable enterprise
applications to achieve the target of 98,50% high-availability.</li>
                                
                                <li>Coordinated with clients and stakeholders, identified appropriate solutions that meet technical and functional
                                requirements, implemented POCs, wrote documentation.</li>
                                <li>Ran Agile methodology of Scrum, Sprints, and Retrospective.</li>
                            </ul>
                        </div>
                        <div>
                            <h3>Senior Software Developer </h3>
                            <p><b>Financial Project (05/2019 - 01/2022)</b></p>
                            <ul>
                                <li>Developed SOAP and RESTful Web Services, Microservices, and Message-base with Kafka, RabbitMQ, Spring Boot, Spring Cloud Config, Hibernate/JPA, Oracle DB, SQL Server, and MongoDB</li>
                                <li>Wrote Unit Tests, Test coverage, and Integration Tests using JUnit, Mockito, and Rest Assured contributing to the overall quality and avoiding production incidents</li>
                                <li>Defined standard of API principles, versioning, and coding conventions, and reviewed pull requests to ensure the quality of code, and reduced bugs</li>
                                <li>_Troubleshooted critical incidents through On-call Support</li>
                                <li>Migrated the mobile app backend to AWS cloud infrastructure using API gateway, Cognito, AWS Lambda, EC2, S3, and PostgreSQL (RDS)</li>
                                <li>Monitored and optimized service performance using Kubernetes, Splunk to timely alert before an incident occurs, resulting in reduced system downtime to zero during business hours</li>
                                <li>Worked with the DevOps team to implement CI/CD pipelines with Jenkins, Git, and deploy applications with load balancer by using Docker, Kubernetes, and K8s</li>
                            </ul>
                        </div>
                        
                        

                        <div>
                            <h3>Senior Software Developer </h3>
                            <p><b>Insurance Project | Telecom Project | Mobile Project (12/2014 – 04/2019)</b></p>
                            <ul>
                                <li>Gathered, implemented, and maintained the Medical and Refund System web applications by using Spring MVC framework, RichFaces, JBoss EAP, Oracle database, and WebLogic to help the insurance applications separately manage businesses for each department</li>
                                <li>Designed database structure for those systems, wrote technical documents, unit test, coded, and deployed systems to SIT, UAT, and PROD environments to make the systems productive</li>
                                <li>Developed and maintained applications for Direct TV satellite Systems in the US. Used EJB3, Google Web Toolkit (GWT), Web Service SOAP, and Jenkins.</li>
                                <li>Designed fragment and UI of Android application for the logistics business</li>
                                <li>Developed, and tested the mobile application, and took responsibility for the quality of the application to make it pass all Japan's customer requirements such as quality standards, translation, and documentation</li>
                                <li>Designed and developed from scratch the new web-based application to manage Tenants using AngularJS, Spring framework, Hibernate, and Oracle DB technologies</li>
                            </ul>
                            
                        </div>

                        <div>
                            <h3> Software Engineer </h3>
                            <p><b>Insurance Project (09/2011 – 11/2014)</b></p>
                            <ul>
                                <li>Developed and maintained the FSS ExceedJ system - the system developed as a General Insurance back-office administration & processing system for P & C lines in the US. Migrated successfully from Cobol to Java</li>
                                <li>Successfully built a tool to convert Cobol to Java</li>
                                <li>Developed productive Rating & Underwriting using multithread in Java to handle thousands of XML rules in seconds</li>
                            </ul>
                            
                        </div>

                        <div>
                            <h3>Software Developer </h3>
                            <p><b>Bank Card System (05/2009 – 08/2011)</b></p>
                            <ul>
                                <li>Built web applications to manage the bank card systems that can issue and manage the information of cardholders. Integrated with Core banking system, ATMs and Merchant’s POS</li>
                                <li>Monitoring transactions of the card system, issuing cards process, and payment process</li>
                                
                            </ul>
                            
                        </div>





                        {/* <p>
                            Introductory Words -----
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                            incididunt ut labore et dolore magna aliqua.
                        </p>
                        <table className="table table-responsive table-light table-hover ">
                            <thead>
                                <th>Position</th>
                                <th>Location</th>
                                <th>Company</th>
                                <th>Years</th>
                                <th>Description</th>
                            </thead>
                            <tbody>
                                {
                                    experienceinfoList.map(( oneInfo, index ) => {
                                        return (
                                            <tr key={index}>
                                                <td>{oneInfo.name}</td>
                                                <td>{oneInfo.location}</td>
                                                <td>{oneInfo.course}</td>
                                                <td>{oneInfo.year}</td>
                                                <td>{oneInfo.score}</td>
                                            </tr>
                                        )
                                    })
                                }
                            
                            </tbody>
                        </table> */}
                    </div>
                </Container>
            </>
        )
    }
}
 