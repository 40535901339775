import React, { Component } from 'react';

// importing react-bootstrap components
import { Container } from 'react-bootstrap';
// HOME MENU
export default class LandingPage extends Component {

    public render() {
        return (<>
                <Container>
                
                    <h1>
                        WELCOME
                    </h1>
                    <span className='textalignright'>
                    OTHER APPs developed by me:</span>

                    <ul>
                        <li>
                        <a href="http://vantyphamtodos.s3-website-us-east-1.amazonaws.com/" target="_blank" rel="mytodos" >To-Do List</a>
                        </li>
                        <li>
                        <a href="http://vantyweatherbucket.s3-website-us-east-1.amazonaws.com" target="_blank" rel="myweatherapp" >Real-time Weather</a>
                        </li>
                        <li>
                        <a href="http://vanphamshowcasesbucket.s3-website-us-east-1.amazonaws.com" target="_blank" rel="myshowcaces" >Show Cases</a>
                        </li>
                        
                    </ul>
                    
                    
                    
                    <span>
                        This picture is for a high-level architecture of this website
                    <img id="architecture-photo" src="./assets/diagram.png" alt="architecture" />
                    </span>
                    
                </Container>
            </>
        )
    }
}
