import React, { Component } from 'react';

// importing react-bootstrap components
import { Container, Table } from 'react-bootstrap';

// Go to '/src/shared/YourInformation.ts' file to edit information 
import { infoList, basicinfo } from '../../shared/YourInformation';
// About me MENU
export default class PersonalPage extends Component {

    public render() {
        return (<>
                <Container>
                    <h1 id="main-banner-h1">Java Fullstack Developer</h1>
                    <div className="profile-logo">
                        {/* Provide your photo and name */}
                        <img id="profile-photo" src="./assets/my-profile-picture.png" alt="Profile Photo" />
                        <h4 id="profile-name">{basicinfo.yourName}</h4>
                    </div>
                    <div className="profile-info">
                        <p id="profile-info-text">
                        Skilled software engineer with hands-on experience in software development using Java, 
                        JavaScript, API Integration, Spring Boot, Spring Cloud, relational database, and NoSQL. 
                        Strong knowledge in object-oriented programming and service-oriented architecture, 
                        Microservices Architecture, Agile/Scrum, and leveraging APIs to integrate cross-platform 
                        applications in Finance and Insurance domains.
                        </p>
                        <Table className="table table-responsive table-light table-hover ">
                            <tbody>
                                {
                                    infoList.map(( oneInfo, index ) => {
                                        return (
                                            <tr key={index}>
                                                <th style={{ paddingRight: "2rem" }}>
                                                    {oneInfo.key + ": "}
                                                </th>
                                                <td>{oneInfo.value}</td>
                                            </tr>
                                        )
                                    })
                                }
                            
                            </tbody>
                        </Table>
                    </div>
                </Container>
            </>
        )
    }
}
 