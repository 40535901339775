import React, { Component } from 'react';

// importing react-bootstrap components
import { Container } from 'react-bootstrap';

// Go to '/src/shared/YourInformation.ts' file to edit information 
import { achievementsinfoList } from '../../shared/YourInformation';


export default class AchievementsPage extends Component {
 
    public render() {
        return (<>
                <Container>
                    <h1 id="main-banner-h1">Technical Summary</h1>
                    <div className="info">
                        <ul>
                            <li>
                            ARCHITECHTURE | Object Oriented Programming (OOP) • Microservices • Service Oriented Architecture (SOA) • API Design & API Integration • SOLID • Design Patterns
                            </li>
                            <li>
                            BACK-END DEVELOPMENT | Java/J2EE • Spring Framework • Spring Boot • Servlet • SOAP • REST • JSP • Spring MVC • Struts • Hibernate/JPA • JUnit/Mockito • OAuth 2.0 • OpenID Connect • Docker
                            </li>
                            <li>
                            FRONT-END DEVELOPMENT | JavaScript • Angular • Jasmine/Karma • React.JS • TypeScript • HTML • CSS • JSON
                            </li>
                            <li>
                            WEB SERVERS/DATABASES | Tomcat • WebLogic • JBoss • WebSphere • MS SQL • Oracle • PL/SQL • DB2 • PostgreSQL • MongoDB • DynamoDB
                            </li>
                            <li>
                            DATA PIPELINES | ActiveMQ • Kafka • Jenkins • Azure Pipeline • AWS CodeDeploy
                            </li>
                            <li>
                            TOOLS | Maven • Gradle • Git • SVN • IntelliJ • Eclipse • DrawIO • Postman • Insomnia • SoapUI • Miro • Jira • Wiki
                            </li>
                            <li>PLATFORMS | Windows • Linux • Kubernetes • AWS (EC2, IAM, AWS Lambda, S3, RDS, ALB, SNS, ECS/Fargate, Cognito)</li>
                            <li>OTHERS | Kong API Gateway • Splunk • PCIDSS • TrueID • Waterfall • Agile • SCRUM/Kanban</li>
                        </ul>
                        <p>
                        








                        </p>
                        <table className="table table-responsive table-light table-hover ">
                            <thead>
                                <th>Programming Languages</th>
                                <th>Frameworks</th>
                                <th>Techstack</th>
                                <th>Topics</th>
                                <th>Others</th>
                            </thead>
                            <tbody>
                                {
                                    achievementsinfoList.map(( oneInfo, index ) => {
                                        return (
                                            <tr key={index}>
                                                <td>{oneInfo.name}</td>
                                                <td>{oneInfo.location}</td>
                                                <td>{oneInfo.date}</td>
                                                <td>{oneInfo.topic}</td>
                                                <td>{oneInfo.result}</td>
                                            </tr>
                                        )
                                    })
                                }
                            
                            </tbody>
                        </table>
                    </div>
                </Container>
            </>
        )
    }
}
 