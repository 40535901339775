// All your Information will be stored here

// Write Basic Information
export const basicinfo = {
    yourName: "Van Ty Pham",
    email: "van.phamty@gmail.com",
    phone: "+1 (946) 268-8164",
    website: "https://vantypham.com"
}

// Provide your personal information here and add more
export const infoList = [
    {
        key: "Full name",
        value: "Van Ty Pham"
    },{
        key: "Birth Place",
        value: "Vietnam"
    },{
        key: "Date of Birth",
        value: "xx/02/198x"
    },{
        key: "Gender",
        value: "Male"
    },{
        key: "Email",
        value: "van.phamty@gmail.com"
    },{
        key: "Address",
        value: "Fairfield, Iowa 52556"
    },{
        key: "Company",
        value: "MIU"
    },{
        key: "Currently",
        value: "Coder"
    }
];

// Provide your academics information here and add more
export const academicinfoList = [
    {
        name: "Maharishi International University",
        location: "1000 N 4th S, Fairfield, Iowa 52557",
        course: "Master's in Computer Science",
        year: "2023-2026",
        score: "3.8"
    },{
        name: "Nong Lam University of Ho Chi Minh",
        location: "Ho Chi Minh City, Vietnam 70000",
        course: "BA's in Information Technology",
        year: "2003-2007",
        score: "3.3"
    }
];

// Provide your Achievements information here and add more
export const achievementsinfoList = [
    {
        name: "Java, JavaScript", //Programming Languages
        location: "Spring, Spring Boot, Angular, SQL", //Frameworks
        topic: "SOAP, RESTful, Microservices, Reactive Programming", //Techstack
        date: "OOP, SOA, Event-driven, Design Pattern, SOLID", //Topics
        result: "JUnit, Mockito, Jenkins, Kubernetes, AWS, Lambda, EC2, API gateway, SNS, SQS" //Others
    },
];

// Provide your Experience information here and add more
export const experienceinfoList = [
    {
        name: "Development Professional",
        location: "Iowa, USA",
        course: "Maharishi International University",
        year: "09/2023 - 05/2024",
        score: "Built a Web Store application to allow customers to buy products and pay by credit card using Angular 16 component-based and reactive web with Observable, NgRx, TyeScript, Karma & Jasmine test, Node JS, deployed to AWS S3, API gateway, Lambda, and DynamoDB using AWS CodeBuild, CodeDeploy"
    },
    {
        name: "Technical Team Lead",
        location: "Ho Chi Minh, Vietnam",
        course: "Home Credit",
        year: "1/2022 - 8/2023",
        score: "•	Developed robust API Services that handled thousands of concurrence requests per second for different domain modules such as payment, refund, eKYC and OCR, buy now pay later, Genesys call center, voicebot/chatbot, and Financial Services of POS loans, cash loans, involving loans for million customers."
    },
    {
        name: "consectetur adipiscing elit",
        location: "adipiscing, YashHj, Uio",
        course: "incididunt",
        year: "1990-1995",
        score: "9.6"
    },{
        name: "consectetur adipiscing elit",
        location: "adipiscing, YashHj, Uio",
        course: "incididunt",
        year: "1990-1995",
        score: "9.6"
    },{
        name: "consectetur adipiscing elit",
        location: "adipiscing, YashHj, Uio",
        course: "incididunt",
        year: "1990-1995",
        score: "9.6"
    }
];

// Provide your Social Media information here and add more
export const socialinfoList = [
    {
        name: "LinkedIn",
        link: "https://www.linkedin.com/in/van-compro1799"
    },{
        name: "GitHub",
        link: "https://github.com/vantypham"
    },{
        name: "ShowCases",
        link: "http://vanphamshowcasesbucket.s3-website-us-east-1.amazonaws.com/"
    }
];

// Provide your Hobbies information here and add more
export const hobbiesinfoList = [
    {
        subject: "consectetur adipiscing",
        info: "adipiscing, YashHj, Uio Duis aute, irure dolor, in reprehenderit"
    },{
        subject: "consectetur adipiscing",
        info: "adipiscing, YashHj, Uio Duis aute, irure dolor, in reprehenderit"
    },{
        subject: "consectetur adipiscing",
        info: "adipiscing, YashHj, Uio Duis aute, irure dolor, in reprehenderit"
    },{
        subject: "consectetur adipiscing",
        info: "adipiscing, YashHj, Uio Duis aute, irure dolor, in reprehenderit"
    }
];